import {
  Button,
  Checkbox,
  Col,
  ColProps,
  Form,
  message,
  Row,
  RowProps,
} from "antd";
import FileSelector from "components/reusable/FileSelector";
import { useShowInputContext } from "context/ShowInputContext";
import {
  useInvoiceConnectionList,
  useInvoiceUpsert,
} from "hooks/queries/useInvoice";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiPencil, BiSave } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { InstallationSchema } from "types/installation";
import { UpsertInvoiceRequestBodyDTO } from "types/invoice";
import { formatDateYYYYMMDD } from "utils/global";
import { GERMAN_NO, GERMAN_YES } from "utils/global/constants";
import InvoiceFilePreview from "./InvoiceFilePreview";
import InvoiceLabelValue from "./InvoiceLabelValue";
import InvoiceTogglingDatePicker from "./InvoiceTogglingDatePicker";
import InvoiceTogglingInput from "./InvoiceTogglingInput";

const INVOICE_ACCEPTED_FILE_TYPE = {
  "application/pdf": [".pdf"],
};

const colStyles: ColProps = {
  xs: 24,
  md: 12,
};

const rowStyles: RowProps = {
  gutter: 16,
};

export default function Invoice({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();

  const [file, setFile] = useState<File | null>(null);

  const { showInput, setShowInput } = useShowInputContext();

  const [form] = Form.useForm<UpsertInvoiceRequestBodyDTO>();

  const { mutate, isLoading } = useInvoiceUpsert(installation.id);

  const { data } = useInvoiceConnectionList(
    installation.id,
    installation.invoice?.id
  );

  const connections =
    data?.data.filter((con) => con !== installation.payment.paymentId) ?? [];

  // Invoice is connected to another booking
  const isConnected = connections.length > 0;

  const onFileChange = useCallback((f: File | null) => {
    setFile(f);
    form.setFields([
      {
        name: "file",
        errors: undefined,
        value: f,
      },
    ]);
  }, []);

  const onSubmit = (v: UpsertInvoiceRequestBodyDTO) => {
    const data = {
      ...v,
      file: file ? file : undefined,
      invoiceDate: formatDateYYYYMMDD(v.invoiceDate),
      invoicePaidOn: formatDateYYYYMMDD(v.invoicePaidOn),
    };
    mutate(data, {
      onSuccess: () => {
        message.success(t("general:updatedSuccess"));
        onFileChange(null);
        setShowInput(false);
      },
      onError: () => {
        message.error(t("general:updateFail"));
      },
    });
  };

  const invoiceDate = installation.invoice?.invoiceDate
    ? formatDateYYYYMMDD(installation.invoice.invoiceDate)
    : null;

  const invoicePaidOn = installation.invoice?.invoicePaidOn
    ? formatDateYYYYMMDD(installation.invoice.invoicePaidOn)
    : null;

  return (
    <Form
      form={form}
      validateTrigger="onChange"
      onFinish={onSubmit}
      autoComplete="off"
    >
      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-center gap-2">
          <div className="flex items-baseline">
            <h5 className="underline">{t("invoice:invoice")}:</h5>&ensp;
            {isConnected && (
              <span style={{ color: "red" }}>
                {t("invoice:connect.invoiceUpdateWarn")}
              </span>
            )}
          </div>
          {showInput ? (
            <div className="flex gap-2">
              <Button
                key="save"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                <BiSave />
              </Button>
              <Button
                key="cancel"
                type="default"
                className="btn-outline"
                onClick={() => {
                  setShowInput(false);
                  onFileChange(null);
                }}
              >
                <RxCross2 />
              </Button>
            </div>
          ) : (
            <Button
              key="edit"
              type="primary"
              htmlType="button"
              onClick={() => setShowInput(true)}
            >
              <BiPencil />
            </Button>
          )}
        </div>
        <div>
          <Row {...rowStyles}>
            <Col xs={24}>
              <Form.Item name="file">
                <FileSelector
                  acceptedFileType={INVOICE_ACCEPTED_FILE_TYPE}
                  previewColumn={
                    <InvoiceFilePreview
                      file={file}
                      invoice={installation.invoice}
                      onFileChange={onFileChange}
                      showInput={showInput}
                      installationId={installation.id}
                    />
                  }
                  value={file}
                  setValue={onFileChange}
                  multiple={false}
                  maxSize={10} // 10 MB
                  disabled={!showInput}
                  style={{
                    height: "160px",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowStyles}>
            <Col {...colStyles}>
              <InvoiceTogglingInput
                label={t("invoice:field.invoiceNumber")}
                name="invoiceNumber"
                rules={[
                  {
                    required: true,
                    message: t("warnings:required"),
                  },
                ]}
                initialValue={installation.invoice?.invoiceNumber ?? null}
              />
            </Col>
            <Col {...colStyles}>
              <InvoiceTogglingDatePicker
                label={t("invoice:field.invoiceDate")}
                name="invoiceDate"
                rules={[
                  {
                    required: true,
                    message: t("warnings:required"),
                  },
                ]}
                initialValue={invoiceDate ?? null}
              />
            </Col>
          </Row>
          <Row {...rowStyles}>
            <Col {...colStyles}>
              <InvoiceTogglingInput
                label={t("invoice:field.amount")}
                name="amount"
                rules={[
                  {
                    required: true,
                    message: t("warnings:required"),
                  },
                  {
                    pattern: /^\d{1,8}(\.\d{1,2})?$/,
                    message: t("warnings:invalidValue"),
                  },
                ]}
                initialValue={installation.invoice?.amount.toString() ?? null}
                inputType="number"
              />
            </Col>
            <Col {...colStyles}>
              <InvoiceLabelValue
                label={t("invoice:field.paid")}
                initialValue={
                  installation.invoice?.paid ? GERMAN_YES : GERMAN_NO
                }
                showInput={showInput}
              />
              {showInput && (
                <Form.Item
                  initialValue={installation.invoice?.paid}
                  name="paid"
                  valuePropName="checked"
                >
                  <Checkbox>{t("invoice:field.paid")}</Checkbox>
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row {...rowStyles}>
            <Col {...colStyles} />
            <Col {...colStyles}>
              <InvoiceTogglingDatePicker
                label={t("invoice:field.invoicePaidOn")}
                name="invoicePaidOn"
                rules={[
                  {
                    required: true,
                    message: t("warnings:required"),
                  },
                ]}
                initialValue={invoicePaidOn ?? null}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );
}
